<template>
  <div>
    <v-container class="container-custom">
      <v-row>
        <v-col>
          <div>
            <h1>Reviews</h1>

            <div
              class="review-container"
              v-for="(data, index) in reviews"
              :key="index"
            >
              <div class="review-header">
                <div class="rating-section">
                  <div class="rating-auth">
                    <span class="avatar-letter text-uppercase">{{
                      data.fullName[0]
                    }}</span>
                    <div>
                      <h2 class="text-capitalize">{{ data.fullName }}</h2>
                      <div class="rating">
                        <v-rating
                          v-model="data.rating"
                          size="30"
                          background-color="gray"
                          color="amber"
                          dense
                          readonly
                        ></v-rating>
                      </div>
                    </div>
                  </div>
                  <p class="review-date">{{ data.createdAt.slice(0, 10) }}</p>
                </div>
              </div>
              <div class="review-content">
                <h3>{{ data.title }}</h3>
                <p>
                  {{ data.description }}
                </p>
              </div>
            </div>
            <div class="add-review-section">
              <button @click="toggleForm" class="add-review-btn">
                <v-icon class="pen-icon">mdi-pencil</v-icon>
                Write a review
              </button>
              <div class="form-container" v-if="showForm">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="rating-form"
                  @submit.prevent="submitForm"
                >
                  <div class="form-div">
                    <v-text-field
                      v-model="fullName"
                      outlined
                      label="Full Name"
                      :rules="[(v) => !!v || 'Name is required']"
                    ></v-text-field>
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      :rules="[
                        ((v) => !!v || 'E-mail is required',
                        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
                      ]"
                    ></v-text-field>
                  </div>
                  <div class="form-rating-star">
                    <p>Rating</p>
                    <v-rating
                      v-model="rating"
                      size="30"
                      background-color="gray"
                      color="amber"
                      dense
                    ></v-rating>
                  </div>
                  <v-text-field
                    v-model="title"
                    outlined
                    label="Title for Review"
                  ></v-text-field>

                  <v-textarea
                    v-model="description"
                    outlined
                    label="Describe the experience with the product"
                  ></v-textarea>
                  <v-checkbox
                    v-model="recommended"
                    class="hidefield"
                    label="Was the blog helpful?"
                  ></v-checkbox>

                  <div class="form-btn">
                    <v-btn
                      type="submit"
                      :loading="submitLoading"
                      class="primary"
                      style="height: 40px"
                      >Submit</v-btn
                    >
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
        {{ snackbarText }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Review",

  props: ["reviews", "id"],
  data() {
    return {
      showForm: false,
      valid: true,
      fullName: "",
      email: "",
      rating: 5,
      title: "",
      description: "",
      recommended: false,
      // connectedTo: undefined,
      submitLoading: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
    };
  },
  computed: {
    ...mapGetters(["allReviews", "singleReview", "isLoadingReview"]),
  },
  methods: {
    ...mapActions(["createReview", "fetchReviewList"]),
    toggleForm() {
      this.showForm = !this.showForm;
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        const formData = {
          fullName: this.fullName,
          email: this.email,
          rating: this.rating,
          title: this.title,
          description: this.description,
          recommended: this.recommended,
          connectedTo: this.id,
        };

        try {
          await this.createReview(formData);
          // window.location.reload();
          this.showForm = false;
          this.resetForm();
          this.$emit("callBack");
          this.showSnackbar("Review submitted successfully", "success");
        } catch (error) {
          console.error("Error creating review:", error);
          this.showSnackbar("Error submitting review", "error");
        } finally {
          this.submitLoading = false;
        }
      }
    },
    resetForm() {
      this.fullName = "";
      this.email = "";
      this.rating = 1;
      this.title = "";
      this.description = "";
      this.recommended = false;
    },
    showSnackbar(text, color = "success") {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
  created() {
    this.createReview();
    this.fetchReviewList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
h1 {
  font-size: $titlefont;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
}
.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position: center center;
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headbanner {
    height: 40vh;
    width: 100%;
    background-position: center;
  }
  section {
    overflow: hidden;
  }
}
.review-header {
  .rating-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rating-auth {
    display: flex;
    align-items: center;
    gap: 20px;
    span {
      background: #1876d2;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 30px;
      color: white;
    }
    .star-icon {
      color: #ffa001;
    }
  }
}
.review-likes {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  .likes {
    display: flex;
    align-items: center;
    gap: 15px;
    p {
      margin-top: 4px;
    }
    .like-button {
      display: flex;
      gap: 5px;
      cursor: pointer;
      align-items: center;
      .like-icon {
        font-size: 18px;
      }
    }
  }
}
.review-content {
  h3 {
    margin-top: 17px;
  }
}
.add-review-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0;
  width: 100%;
  .add-review-btn {
    border: 2px solid #757575;
    padding: 10px 20px;
    color: #757575;
    border-radius: 5px;
    .pen-icon {
      font-size: 17px;
    }
  }
}
.form-container {
  width: 60%;
  margin-top: 45px;
}
.form-div {
  display: flex;
  align-items: center;
  gap: 25px;
}
.form-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 5px;
  width: 100%;
}
.form-rating-star {
  margin-bottom: 22px;
}
@media screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
  .form-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 0px;
  }
}
.blog-section {
  padding: 20px 0 40px 0;
}
.review-container {
  margin-top: 40px;
}
</style>
